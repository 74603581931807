<template>
  <div class="cate-container">
    <van-skeleton title :row="6" :loading="loading">
      <van-nav-bar
        title="分类"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        safe-area-inset-top
        :border="false"
      />
      <van-tree-select
        :items="items"
        height="100vh"
        v-model:main-active-index="activeIndex"
      >
        <template #content>
          <van-divider
            :style="{ width: '50%', margin: '0 auto', padding: '16px' }"
            >{{ showContents.text }}</van-divider
          >
          <van-grid
            :column-num="3"
            :border="false"
            class="cate-main"
            v-if="showContents.children && showContents.children.length > 0"
          >
            <van-grid-item
              v-for="(item, index) in showContents.children"
              :key="index"
            >
              <template #default>
                <div @click="handleLinkToSearch(item)" class="cate-box">
                  <img class="cate-pic" :src="item.logoUrl" />
                  <div class="cate-name">{{ item.text }}</div>
                </div>
              </template>
            </van-grid-item>
          </van-grid>
          <div v-else>
            暂无分类
          </div>
        </template>
      </van-tree-select>
    </van-skeleton>
    <BottomTabbar />
  </div>
</template>

<script setup>
import { Toast } from "vant";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getSidebarCategoryInfo } from "../../api/common";
import BottomTabbar from "../../components/bottomTabbar.vue";
const items = ref([{}]);
const activeIndex = ref(0);
const loading = ref(true);
const router = useRouter();
const store = useStore();
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const merchantId = computed(() => store.getters.merchantId);
const showContents = computed(() => {
  return items.value[activeIndex.value];
});

const handleLinkToSearch = (item) => {
  console.log("item======", item);
  router.push({
    name: "goodsSearch",
    query: { categoryId: item.categoryId, categoryName: item.text },
  });
};
const formatCategoryList = (data) => {
  let result = [];
  for (let i = 0; i < data.length; i++) {
    let obj = {};
    obj.text = data[i].categoryName;
    obj.categoryId = data[i].categoryId;
    obj.logoUrl = data[i].logoUrl;
    if (
      data[i].childrenCategoryList &&
      data[i].childrenCategoryList.length > 0
    ) {
      obj.children = formatCategoryList(data[i].childrenCategoryList);
    }
    result.push(obj);
  }
  return result;
};
const loadCategoryList = async () => {
  Toast.loading({ duration: 300, message: "加载中" });
  loading.value = true;
  let data = await getSidebarCategoryInfo({
    token: token.value,
    materialId: materialId.value,
  });
  items.value = formatCategoryList(data);
  loading.value = false;
  Toast.clear();
};
const onClickLeft = () => {
  router.back();
};
loadCategoryList();
</script>
<style lang="scss">
.cate-container {
  .van-tree-select__nav {
    width: 100px;
    flex: none;
  }
  .van-tree-select__nav-item {
    .van-sidebar-item__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 72px;
      margin: 0 auto;
      height: 20px;
      line-height: 27px;
    }
  }
  .van-tree-select__content {
    flex: 1;
  }
  .van-grid-item__content {
    background: #fff;
  }
}
</style>
<style lang="scss" scoped>
.cate-main {
  margin-bottom: 50px;
}
.cate-box {
  background: #fff;
  .cate-pic {
    width: 63px;
    height: 54px;
    object-fit: cover;
  }
  .cate-name {
    color: #696974;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 85px;
  }
}
</style>
